import "htmx.org";
import { autofocusFirstEmpty } from "./autofocus";

window.htmx = require("htmx.org");

document.addEventListener("htmx:load", (evt) => {
  if (window.csrf_token) {
    document.body.addEventListener("htmx:configRequest", (event) => {
      event.detail.headers["X-CSRFToken"] = window.csrf_token;
    });
  } else {
    console.warn("[htmx] window.csrf_token is undefined");
  }

  // Debug logging
  if (window.location.hostname === "localhost") {
    window.htmx.logger = (elt, event, data) => {
      if (event == "htmx:configRequest") {
        console.debug(`[htmx] Request: ${data.verb} ${data.path}`, data);
      }
    };
  }
});

// Focus empty inputs with autofocus in newly loaded content
window.addEventListener("htmx:afterSettle", (event) => {
  autofocusFirstEmpty(event.target);
});
