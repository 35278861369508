// Focus the first empty input element with the data-autofocus-empty attribute
// The browser's default implementation of 'autofocus'
export function autofocusFirstEmpty(target = document.body) {
  // console.debug(`autofocusFirstEmpty: ${target}`);
  const inputToFocus = Array.from(
    target.querySelectorAll("input[data-autofocus-empty]")
  ).find(input => input.value === "");
  if (inputToFocus) {
    inputToFocus.focus();
  }
}
