import "bootstrap";
import AOS from "aos";
import feather from "feather-icons";

import "./form-multi-submit-protection";
import "./htmx";

import "./sb-ui-kit/js/scripts.js"; // todo; move this content to here?
import "./index.scss";
import "./logo.scss";
import { autofocusFirstEmpty } from "./autofocus";

window.addEventListener("DOMContentLoaded", (event) => {
  AOS.init({
    disable: "mobile",
    duration: 600,
    once: true
  });

  feather.replace();
});

window.addEventListener("DOMContentLoaded", (event) => {
  autofocusFirstEmpty();
});
